import {
  MDBCard,
  MDBCardBody,
  MDBCardLink,
  MDBCardText
} from 'mdb-react-ui-kit';
import React from 'react';

export function Knowledgebase(props) {
  return (
    <>

  
        
  <MDBCard style={{ display: "block", overflow: "scroll", height: "40vh" }}>
        <>
      {props.knowledgebaseList?.length ? props.knowledgebaseList.map((knowledgebase, index) => {
          return <MDBCardBody style={{ paddingBottom: "5px" }} key={index}>
            <h6>{knowledgebase.title}</h6>
            {/* <MDBCardText>
              {knowledgebase.title}
            </MDBCardText> */}
            <MDBCardLink href='www.lifewire.com/mobile-phone-troubleshooting'>{knowledgebase.url}</MDBCardLink>
          </MDBCardBody>
      }) :
        <>
          <MDBCardBody style={{ paddingBottom: "5px" }}>
            <h6 className='placeholder-glow'>
              <span class="placeholder col-6"></span>
            </h6>
            <MDBCardText className='placeholder-glow'>
              <span class="placeholder col-12"></span>
            </MDBCardText>
            <MDBCardLink href='www.lifewire.com/mobile-phone-troubleshooting' className='placeholder-glow'>
              <span class="placeholder col-10"></span>
            </MDBCardLink>
          </MDBCardBody>

          <MDBCardBody style={{ paddingBottom: "5px" }}>
            <h6 className='placeholder-glow'>
              <span class="placeholder col-6"></span>
            </h6>
            <MDBCardText className='placeholder-glow'>
              <span class="placeholder col-12"></span>
            </MDBCardText>
            <MDBCardLink href='www.lifewire.com/mobile-phone-troubleshooting' className='placeholder-glow'>
              <span class="placeholder col-10"></span>
            </MDBCardLink>
          </MDBCardBody>

          <MDBCardBody style={{ paddingBottom: "5px" }}>
            <h6 className='placeholder-glow'>
              <span class="placeholder col-6"></span>
            </h6>
            <MDBCardText className='placeholder-glow'>
              <span class="placeholder col-12"></span>
            </MDBCardText>
            <MDBCardLink href='www.lifewire.com/mobile-phone-troubleshooting' className='placeholder-glow'>
              <span class="placeholder col-10"></span>
            </MDBCardLink>
          </MDBCardBody>

          <MDBCardBody style={{ paddingBottom: "5px" }}>
            <h6 className='placeholder-glow'>
              <span class="placeholder col-6"></span>
            </h6>
            <MDBCardText className='placeholder-glow'>
              <span class="placeholder col-12"></span>
            </MDBCardText>
            <MDBCardLink href='www.lifewire.com/mobile-phone-troubleshooting' className='placeholder-glow'>
              <span class="placeholder col-10"></span>
            </MDBCardLink>
          </MDBCardBody>

          <MDBCardBody style={{ paddingBottom: "5px" }}>
            <h6 className='placeholder-glow'>
              <span class="placeholder col-6"></span>
            </h6>
            <MDBCardText className='placeholder-glow'>
              <span class="placeholder col-12"></span>
            </MDBCardText>
            <MDBCardLink href='www.lifewire.com/mobile-phone-troubleshooting' className='placeholder-glow'>
              <span class="placeholder col-10"></span>
            </MDBCardLink>
          </MDBCardBody>

          <MDBCardBody style={{ paddingBottom: "5px" }}>
            <h6 className='placeholder-glow'>
              <span class="placeholder col-6"></span>
            </h6>
            <MDBCardText className='placeholder-glow'>
              <span class="placeholder col-12"></span>
            </MDBCardText>
            <MDBCardLink href='www.lifewire.com/mobile-phone-troubleshooting' className='placeholder-glow'>
              <span class="placeholder col-10"></span>
            </MDBCardLink>
          </MDBCardBody></>
      }

        </>
      
 
     </MDBCard>
     <hr className="hr" />


     <MDBCard style={{ display: "block", overflow: "scroll", height: "25.5vh" }}>
      

        
      <MDBCardBody style={{ paddingBottom: "5px" }}>
        {props.isCallEnded && props.callSummary ? 
           <h5 className='placeholder-glow'>
        {props.callSummary}
        </h5>
      :  <MDBCardBody>
          <p className="mb-0 placeholder-glow">
              <span class="placeholder col-12"></span>
          </p>
          <p className="mb-0 placeholder-glow">
              <span class="placeholder col-12"></span>
          </p>
          <p className="mb-0 center" style={{ textAlign: "center" }}>
              End call to see the summary.
          </p>
          <p className="mb-0 placeholder-glow">
              <span class="placeholder col-12"></span>
          </p>
          <p className="mb-0 placeholder-glow">
              <span class="placeholder col-12"></span>
          </p>
          
      </MDBCardBody>
      }
          
        </MDBCardBody>
      
</MDBCard>
     </>
  );
}