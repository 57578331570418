import { MDBBadge, MDBContainer } from 'mdb-react-ui-kit';
import React from 'react';

export function Keywords(props) {
    return (
        <MDBContainer style={{ textAlign: "center", minHeight: "3vh" }} >
            {/* <MDBCard>
                <MDBCardText> */}
            {props.keywords.length ? props.keywords.map((keyword, index) => {
                return <MDBBadge pill className="mx-2" color='danger' style={{ fontSize: 14 }} key={index}>{keyword}</MDBBadge>
            }) : <h3>
                <small class="text-muted placeholder-glow">
                <span class="placeholder col-9"></span>
                </small>
            </h3>}
            {/* <MDBBadge pill className="mx-2" color='danger' style={{ fontSize: 14 }}>mobile turn on</MDBBadge>
            <MDBBadge pill className="mx-2" color='danger' style={{ fontSize: 14 }}> you're having trouble</MDBBadge>
            <MDBBadge pill className="mx-2" color='danger' style={{ fontSize: 14 }}>I'll assist you</MDBBadge>
            <MDBBadge pill className="mx-2" color='danger' style={{ fontSize: 14 }}>your name  model</MDBBadge>
            <MDBBadge pill className="mx-2" color='danger' style={{ fontSize: 14 }}>your name  model</MDBBadge>
            <MDBBadge pill className="mx-2" color='danger' style={{ fontSize: 14 }}>your name  model</MDBBadge>
            <MDBBadge pill className="mx-2" color='danger' style={{ fontSize: 14 }}>My</MDBBadge> */}
            {/* </MDBCardText>
            </MDBCard> */}

        </MDBContainer>
    );
}