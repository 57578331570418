import { useState } from "react"
import { Rating } from '@smastrom/react-rating'
import '@smastrom/react-rating/style.css'
import { EmojiRating } from "emoji-rating-component"

// export function RatingS() {
//     const [selected, setSelected] = useState(0)
//     return <EmojiRating
//         selected={selected}
//         onSelected={(selected) => {
//             setSelected(selected)
//         }}
//     />
// }


import React from 'react';
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBBtn
} from 'mdb-react-ui-kit';

export function RatingS(props) {
    return (
        <>
            <MDBCard className="d-flex justify-content-between mb-4" style={{ alignItems: "center" }}>
                <MDBCardBody >
                    {/* <MDBCardTitle>Satisfaction</MDBCardTitle> */}
                    <MDBCardText>
                        <EmojiRating
                            selected={props.emotion? props.emotion : null}
                            onSelected={(selected) => {
                            }}
                            iconSize={60}
                        />
                    </MDBCardText>
                </MDBCardBody>
            </MDBCard>
        </>
    );
}
