import axios from "axios";
import {
  MDBCol,
  MDBContainer,
  MDBRow
} from "mdb-react-ui-kit";
import React, { createContext, useEffect, useState } from "react";
import chat1 from "./chat/assets/chat1.json";
import chat2 from "./chat/assets/chat2.json";
import chat3 from "./chat/assets/chat3.json";
import chat4 from "./chat/assets/chat4.json";
import ninja from "./chat/assets/ninja.png";
import { Chat } from "./chat/chat";
import { Keywords } from "./chat/keywords";
import { Knowledgebase } from "./chat/knowledgebase";
import { RatingS } from "./chat/rating";

export const AppContext = createContext(
  {
    isCallStarted: false,
    endCall: () => { },
    startCall: () => { },
  }
)

const emotiosAPI = "/emotion"
const keyWordsAPI = "/keywords"
const searchAPI = "/keywords/search"
const summaryAPI = "/summary"


export default function App() {
  const [currentChat, setCurrentChat] = useState([])

  const [isCallStarted, setIsCallStarted] = React.useState(false)
  const [isCallEnded, setIsCallEnded] = React.useState(false)

  const [APIpath, setAPIPath] = React.useState(null)
  const [conversation, setConversation] = React.useState(chat1)
  
  
  const [emotions, setEmotionLevels] = React.useState(null)
  const [keywords, setKeyWords] = React.useState([])
  const [knowledgebaseList, setKnowledgebaseList] = React.useState([])
  const [callSummary, setCallSummary] = React.useState([])


  const setConversationHandler = (conversation) => {
    if(conversation === "chat1"){
      setConversation(chat1)
    }else if(conversation === "chat2"){
      setConversation(chat2)
    } else if(conversation === "chat3"){
      setConversation(chat3)
    } else if(conversation === "chat4"){
      setConversation(chat4)
    }
  }
  

  const counter = React.useRef(0);
  const chatListRef = React.useRef([]);

  useEffect(()=>{
    fetch('config.json').then((res)=>res.json()).then((data)=>{
      setAPIPath(data.apiEndpoint)
     })
  },[])

  useEffect(() => {
    if (counter.current < conversation.length && !isCallEnded && isCallStarted && chatListRef.length !== currentChat.length && APIpath) {
      const timer = setTimeout(() => {
        let modifiedChat = [...currentChat]
        modifiedChat.unshift(conversation[currentChat.length])
        counter.current = modifiedChat.length
        setCurrentChat(modifiedChat)
        chatListRef.current = modifiedChat

        const reversedCoversation = [...modifiedChat]
        reversedCoversation.reverse()
        //get emotion level
        axios(
          APIpath + emotiosAPI, {
          method: 'POST',
          data: {
            conversation: reversedCoversation
          }
        }).then((response) => {
          if(response?.data?.result && !isNaN(response?.data?.result)){
            setEmotionLevels(response?.data?.result-1);
          }
          
        }).catch((error) => {
          //Do nothing
        });

        //get key words and search result
        axios(
          APIpath + keyWordsAPI, {
          method: 'POST',
          data: {
            conversation: reversedCoversation
          }
        }).then((response) => {
          if(response?.data?.keywords?.length){
            setKeyWords(response?.data?.keywords);
          }
          if(response?.data?.keywords?.length){
              axios(
                APIpath + searchAPI, {
                method: 'POST',
                data: {
                  keywords: response?.data?.keywords
                }
              }).then((searchResult) => {
                if(searchResult?.data?.result?.length){
                  setKnowledgebaseList(searchResult?.data?.result)
                }
                
              
              }).catch((error) => {
                //Do nothing
              });
          }
          
          // setKnowledgebaseList([{
          //   title: "Mobile Phone Troubleshooting - Lifewire",
          //   description: "Lifewire's guide to troubleshooting mobile phones, covering everything from common problems to advanced repair.",
          //   link: "www.lifewire.com/mobile-phone-troubleshooting"
          // }])
        }).catch((error) => {
          //Do nothing
        });

       //summary API
       axios(
        APIpath + summaryAPI, {
        method: 'POST',
        data: {
          conversation: reversedCoversation
        }
      }).then((response) => {
        if(response?.data?.result?.length){
          setCallSummary(response?.data?.result);
        }
        
      }).catch((error) => {
        //Do nothing
      });

      }, 6000);
      return () => {
        clearTimeout(timer)
      };
    }
  }, [currentChat, isCallStarted, isCallEnded, APIpath, conversation]);
  // emotions, keywords, knowledgebaseList

  const endCall = () => {
    setIsCallStarted(false)
    setIsCallEnded(true)
  }


  const startCall = () => {
    setCurrentChat([])
    setEmotionLevels(null)
    setKeyWords([])
    setKnowledgebaseList([])
    setCallSummary([])
    counter.current = 0;
    setIsCallStarted(true)
    setIsCallEnded(false)
  }

  const [isCallPage, setIsCallPage] = React.useState(true)

  return (
    <>
      <nav class="navbar navbar-expand-lg navbar-light bg-white">
        <div class="container-fluid">
          <div class="btn-group shadow-0">
            <select style={{borderRadius: "4px", padding: "3px", borderColor: "whitesmoke"}} onChange={(selectedCoversation) => {
               setConversationHandler(selectedCoversation.target.value)
               }}>
              <option value="chat1"><h4>Conversation 1</h4></option>
              <option value="chat2">Conversation 2</option>
              <option value="chat3">Conversation 3</option>
              <option value="chat4">Conversation 4</option>
            </select>
          </div>
        </div>
        <img
            src={ninja}
            alt="avatar"
            className="rounded-circle d-flex align-self-start me-3 shadow-1-strong"
            width="40"
        />
      </nav>

      {
        isCallPage ? <> <MDBContainer fluid className="py-2 " style={{ backgroundColor: "#eee" }}>
          <MDBRow>
            <Chat
              currentChat={currentChat}
              endCall={endCall}
              startCall={startCall}
              isCallStarted={isCallStarted}
            />
            <MDBCol md="5" lg="5" xl="5" >
              <RatingS emotion={emotions} />
              <hr className="hr" />
              <Keywords keywords={keywords} />
              <hr className="hr" />
              <MDBRow >
                <MDBContainer>
                  <Knowledgebase knowledgebaseList={knowledgebaseList} callSummary={callSummary} isCallEnded={isCallEnded}/>
                </MDBContainer>
              </MDBRow>
            </MDBCol>

          </MDBRow>
        </MDBContainer >
        </> : <> <MDBContainer>
          {/* <MDBRow> */}
            {/* <Summary /> */}
          {/* </MDBRow> */}
        </MDBContainer></>
      }


    </>
  );
}
