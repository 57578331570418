import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBIcon,
    MDBRow,
    MDBTypography
} from "mdb-react-ui-kit";
import React, { useLayoutEffect } from "react";


export function Chat(props) {
    const messagesEndRef = React.createRef()
    useLayoutEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollTop = 1000
        }

        // var elem = document.getElementById('data');
        // elem.scrollTop = 0;
    }, [props.currentChat]);
    // let x = undefined
    // useEffect(() => {
    //     fetch('https://api.github.com/repos/TanStack/query').then(
    //         (res) => {
    //             x = res.json()
    //         }
    //     )
    // }, [])
    // console.log("normal" )
    // console.log(props.currentChat)
    // console.log("reverse")
    //  const x = props.currentChat.sort().reverse()
    // x.reverse()
    // console.log(x)


    // context.endCall()
    return (
        <>
            <MDBCol className="py-2" md="7" lg="7" xl="7" style={{ borderRight: "solid #ffffff45 2px" }}>
                <MDBRow className="overflow-auto" style={{ height: '86vh' }} ref={messagesEndRef}>
                    <MDBTypography listUnStyled style={{ display: "flex", flexDirection: "column-reverse" }}>
                        {
                            props.currentChat?.length ? props.currentChat.map((item, index) => {
                                return item.isCustomer ?
                                    <li className="d-flex justify-content-between mb-4" key={index} style={{ alignSelf: "flex-start" }}>
                                        <img
                                            src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3-bg.webp"
                                            alt="avatar"
                                            className="rounded-circle d-flex align-self-start me-3 shadow-1-strong"
                                            width="60"
                                        />
                                        <MDBCard>
                                            <MDBCardHeader className="d-flex justify-content-between p-3">
                                                <p className="fw-bold mb-0">Customer</p>
                                                <p className="text-muted small mb-0">
                                                    <MDBIcon far icon="clock" /> 12 mins ago
                                                </p>
                                            </MDBCardHeader>
                                            <MDBCardBody>
                                                <p className="mb-0">
                                                    {item.conversation}
                                                </p>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </li> :
                                    <li class="d-flex justify-content-between mb-4" style={{ alignSelf: "flex-end" }} key={index}>
                                        <MDBCard className="w-100">
                                            <MDBCardHeader className="d-flex justify-content-between p-3">
                                                <p class="fw-bold mb-0">Agent</p>
                                                <p class="text-muted small mb-0">
                                                    <MDBIcon far icon="clock" /> 13 mins ago
                                                </p>
                                            </MDBCardHeader>
                                            <MDBCardBody>
                                                <p className="mb-0">
                                                    {item.conversation}
                                                </p>
                                            </MDBCardBody>
                                        </MDBCard>
                                        <img
                                            src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava5-bg.webp"
                                            alt="avatar"
                                            className="rounded-circle d-flex align-self-start ms-3 shadow-1-strong"
                                            width="60"
                                        />
                                    </li>
                            }) : <li className="d-flex justify-content-between mb-4" key={100000} style={{ alignSelf: "flex-start" }}>
                                <img
                                    src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3-bg.webp"
                                    alt="avatar"
                                    className="rounded-circle d-flex align-self-start me-3 shadow-1-strong"
                                    width="60"
                                />
                                <MDBCard style={{ minWidth: "400px" }}>
                                    <MDBCardHeader className="d-flex justify-content-between p-3">
                                        <p className="fw-bold mb-0 placeholder-glow">Customer</p>
                                        <p className="text-muted small mb-0 ">
                                            {/* <MDBIcon far icon="clock" /> 12 mins ago */}
                                        </p>
                                    </MDBCardHeader>
                                    <MDBCardBody>
                                        <p className="mb-0 placeholder-glow">
                                            <span class="placeholder col-12"></span>
                                        </p>
                                    </MDBCardBody>
                                </MDBCard>
                            </li>
                        }


                    </MDBTypography>

                </MDBRow>
                <MDBRow style={{ maxHeight: '5vh', padding: "10px" }} >
                    {props.isCallStarted ? <MDBBtn color="danger" rounded className="float-end" onClick={() => {
                        props.endCall()
                    }}>
                        End call
                    </MDBBtn> : <MDBBtn color="info" rounded className="float-end" onClick={() => {
                        props.startCall()
                    }}>
                        Start call
                    </MDBBtn>}

                </MDBRow>
            </MDBCol>
        </>
    );
}



